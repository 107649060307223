(function () {
    angular.module('informaApp')
        .service('AccountService', AccountService);

    function AccountService(BaseApiService) {
        return {
            update(data) {
                return BaseApiService.put('account', data);
            },

            getCountries() {
                return BaseApiService.get('account/countries')
                    .then(response => response.data);
            },

            getResetPasswordLink() {
                return BaseApiService.get('account/urls/password-reset')
                    .then(response => response.data.link);
            }
        }
    }
})();
